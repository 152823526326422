import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../config/config.service';
import {AuthService} from '@auth0/auth0-angular';
import {Router} from '@angular/router';
import {MergedConnectionService} from '../service/mergedConnection.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {AppComponent} from '../app.component';
import {WarningComponent} from '../warning/warning.component';
import {Jobs} from '../shared/Jobs';
import {WordpressData} from '../shared/WordpressData';
import { DatePipe } from '@angular/common';
import {environment as env} from '../../environments/environment';

@Component({
  selector: 'app-wordpress-settings',
  templateUrl: './wordpress-settings.component.html',
  styleUrls: ['./wordpress-settings.component.scss']
})
export class WordpressSettingsComponent implements OnInit {
    environment: string;
  // tslint:disable-next-line:variable-name
  time_last_job_started = '';
  currentPage = 1;
  fetchData = 20;
  totalPages = 20;
  offset = 0;
  totalCounts = 0;
  filterData = '';
  href = 'https://dev.ceo360.pro/#/';
  wordpressData: WordpressData[] = [];
  jobs: Jobs[] = [{
    key: '',
    type: '',
    inProcess: false,
    state: ''
  }];
  // auth0 data
    token: string;
  user: string;
  userEmail: string;
  userRole: string;
  // tslint:disable-next-line:variable-name
  are_jobsVisible = false;
  isAdmin: boolean;
  profileJson: string = null;
  showOverlay = false;
  columnsToDisplay = ['WordPressId', 'Ceo360Id', 'Imported', 'Status', 'Duplicate', 'CeoDigitalAndPrint', 'CeoDigitalOnly', 'CbmDigitalAndPrint', 'CbmDigitalOnly', 'AltWordpressId', 'DateAdded'];
  // tslint:disable-next-line:variable-name
  constructor(private api: ConfigService, public auth: AuthService, private route: Router, private _mergeservice: MergedConnectionService,
              private snackBar: MatSnackBar, public dialog: MatDialog, private appcomponent: AppComponent)
  {
    this.appcomponent.jobChecking();
  }
  // tslint:disable-next-line:typedef
  snackbar_SucceededPost() {
    const sb = this.snackBar.open('Your settings have successfully been saved!', '', {
      duration: 1000,
      panelClass: ['succeed'],
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
  // tslint:disable-next-line:typedef
  snackbar_FailedPost() {
    const sb = this.snackBar.open('Your settings are NOT saved!', '', {
      duration: 1000,
      panelClass: ['failed'],
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
    modificationProceeded(message: string): void{
        this.snackBar.open(message, '', {
            duration: 2000,
            panelClass: 'succeed',
            horizontalPosition: 'right',
            verticalPosition: 'top'
        });
    }
  GetTimeStamp(): void{
    // tslint:disable-next-line:new-parens
    const date = new Date;
    this.time_last_job_started = localStorage.getItem('date');
  }
  // tslint:disable-next-line:typedef
  showRunningJobs(){
    this.GetTimeStamp();
    this.api.get_Jobs().subscribe(res => {
          // @ts-ignore
          this.jobs = res;
          for (const i of this.jobs){
            if (i.type === 'CegIntegration.Web.Jobs.MainDataSynchronizationJob'){
              this.jobs.splice(this.jobs.indexOf(i), 1);
            }
          }
          this.are_jobsVisible = !(this.are_jobsVisible);
          if (this.are_jobsVisible){
            const img = document.getElementById('research-running-jobs');
            img.style.transform = 'rotate(180deg)';
          }
          else{
            const img = document.getElementById('research-running-jobs');
            img.style.transform = 'rotate(0deg)';
          }
        },
        err => {
          console.log(err);
        });
  }
  deleteJob(jobKey: any): void{
    const dialogRef = this.dialog.open(WarningComponent);
    dialogRef.componentInstance.action = 'DeleteJob';

    dialogRef.afterClosed().subscribe(result => {
      console.log(`popup result: ${result}`);
      if (result === 'yes'){
        this.ProceedDeleteJob(jobKey);
      }
    });
  }
  ProceedDeleteJob(jobKey: any): void{
    const url = this.api.delete_Job(jobKey.toString());

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);

    xhr.setRequestHeader('Content-Type', 'application/json-patch+json');
    xhr.setRequestHeader('accept', '*/*');
    xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
    // tslint:disable-next-line:only-arrow-functions typedef
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        // tslint:disable-next-line:triple-equals
        if (xhr.status == 200){
          console.log('ok');
          document.getElementById('succeeded_snack').click();
          document.getElementById('refreshJobs').click();
        }
        else{
          document.getElementById('failed_snack').click();
        }
      }
    };
    xhr.send();
  }

  refreshJobs(): void{
    this.api.get_Jobs().subscribe(res => {
          // @ts-ignore
          this.jobs = res;
          for (const i of this.jobs){
            if (i.type === 'CegIntegration.Web.Jobs.MainDataSynchronizationJob'){
              this.jobs.splice(this.jobs.indexOf(i), 1);
            }
          }
        },
        err => {
          console.log(err);
        });
  }
    // tslint:disable-next-line:typedef
    filterByDate(){
        console.log('fitlerData', this.filterData );
        this.offset = 0;
        this.currentPage = 1;
        let dataTofilter = '';
        if ( this.filterData !== ''){
            dataTofilter = new DatePipe('en-US').transform(this.filterData, 'MM/dd/yyyy');
        }
        this.showOverlay = true;
        this.api.get_Wordpress_Table(this.offset, this.fetchData, dataTofilter).subscribe(res => {
                console.log(res);
                this.mapResponseToTable(res);
                if ( this.wordpressData.length > 0){
                    this.totalCounts = this.wordpressData[0].totalCounts;
                }
                else{
                    this.totalCounts = 0;
                }
                this.showOverlay = false;
                this.getTotalPages();
            },
            err => {
                console.log(err);
            });
    }
  // tslint:disable-next-line:typedef
  showTable(){
      this.showOverlay = true;
      let dataTofilter = '';
      console.log('this.filterData', this.filterData);
      if ( this.filterData !== '' ){
          dataTofilter = new DatePipe('en-US').transform(this.filterData, 'MM/dd/yyyy');
      }
      this.api.get_Wordpress_Table(this.offset, this.fetchData, dataTofilter).subscribe(res => {
            if (res){
                this.mapResponseToTable(res);
                console.log('res',  this.wordpressData);
                this.totalCounts = this.wordpressData[0].totalCounts;
                console.log('this.wordpressData', this.wordpressData);
            }else{
                this.wordpressData = null;
                this.totalCounts = 0;
            }
            this.showOverlay = false;
            this.getTotalPages();
        },
        err => {
          console.log(err);
        });
  }
  mapResponseToTable(response: any): void{

      // @ts-ignore
      this.wordpressData = response.map((item): WordpressData => ({
          ceoId : item.ceO360_Id,
          wpId: item.wP_Id,
          status: item.done,
          duplicateProfile: item.duplicateProfile,
          sourceSite: item.wP_SiteId,
          createdDate: item.createDate,
          cbmDigitalAndPrint: item.cbmDigitalAndPrint,
          ceoDigitalAndPrint: item.ceoDigitalAndPrint,
          ceoDigitalOnly: item.ceoDigitalOnly,
          cbmDigitalOnly: item.cbmDigitalOnly,
          magazine: item.magazine,
          totalCounts: item.totalCounts,
          altWordpressId: item.altWordpressId
      }));
  }
    // tslint:disable-next-line:typedef
    getTotalPages() {
        if (this.totalCounts % this.fetchData) {
            this.totalPages = Math.floor(this.totalCounts / this.fetchData) + 1;
        } else {
            this.totalPages = Math.floor(this.totalCounts / this.fetchData);
        }
        console.log('totalpages', this.totalPages);
    }
    // tslint:disable-next-line:typedef
  LinkToWP(SiteId: number, wpId: number){
      let link = '';
      switch (SiteId){
          case 1:
              link = 'https://chiefexecutive.net/wp-admin/user-edit.php?user_id=';
              break;
          case 2:
              link = 'https://cexdev.wpengine.com/wp-admin/user-edit.php?user_id=';
              break;
      }
      link += wpId;
      const win = window.open(link, '_blank');
      win.focus();
  }
  // tslint:disable-next-line:typedef
  LinkToCeo(id: number){
      // tslint:disable-next-line:max-line-length
      if (window.location.href === 'http://localhost:4200/#/wordpress-settings' || window.location.href === 'https://amplify-dev.ceo360.pro/#/wordpress-settings') {
          this.href = 'https://dev.ceo360.pro/#/';
      }
      if (window.location.href === 'https://amplify.ceo360.pro/#/wordpress-settings') {
          this.href = 'https://ceo360.pro/#/';
      }
      this.href += 'Person/' + id;
      console.log(this.href);
      const win = window.open(this.href, '_blank');
      win.focus();
  }
    // tslint:disable-next-line:typedef
    goToFirstPage() {
        this.currentPage = 1;
        this.resetOffset();
    }
    // tslint:disable-next-line:typedef
    goToLastPage() {
        this.currentPage = this.totalPages;
        this.offset = ((this.currentPage - 1) * this.fetchData);
        this.showTable();
        console.log('page nr', this.currentPage);
    }
   // tslint:disable-next-line:typedef
    nextPage() {
        console.log('fitlerData', this.filterData );
        if (this.currentPage < this.totalPages) {
            // @ts-ignore
            this.currentPage++;
            this.resetOffset();
        }
        console.log('page nr', this.currentPage);
    }
  // tslint:disable-next-line:typedef
    previousPage() {
        if (this.currentPage > 1) {
            // @ts-ignore
            this.currentPage--;
            this.resetOffset();
        }
        console.log('page nr', this.currentPage);
    }
    // tslint:disable-next-line:typedef
    resetOffset() {
        this.offset = ((this.currentPage - 1) * this.fetchData);
        this.showTable();

    }

  ngOnInit(): void {
      this.api.getAuthToken().subscribe(auth => {
          this.token = auth;
      });
      this.environment =  env.environment ;
      this.auth.user$.subscribe(
        (profile) => {
          (this.profileJson = JSON.parse(JSON.stringify(profile, null, 2)));
          localStorage.setItem('userRole', profile['https://schemas.quickstarts.com/roles'][0]);
          this.userRole = profile['https://schemas.quickstarts.com/roles'][0];
          // console.log('user role', this.userRole);
          if (this.userRole === 'admin'){
            this.isAdmin = true;
            console.log('is admin');
          }
          else{
            this.isAdmin = false;
            console.log('is not admin');
          }
        });
      this.showTable();
  }
  startWordpressApi(): void{
      this.api.startWordPressApi(this.token).then(response => {
          console.log('response', response);
          if (response.status === 200){
              this.modificationProceeded('WordPress Api started');
          }
      });
  }

}
